import { inject } from '@angular/core';
import { CanActivateFn, createUrlTreeFromSnapshot } from '@angular/router';
import { TeamsService } from '@app/services/teams.service';
import { MsalService } from '@azure/msal-angular';
import { Logger } from 'timeghost-api';
const log = new Logger('teamsRouteGuard');
export const teamsRouteGuard: CanActivateFn = async (route, state) => {
  const msal = inject(MsalService);
  const account = msal.instance.getActiveAccount();
  log.debug('teams account: ', account);
  if (!account) return true;
  const teams = inject(TeamsService);
  teams.getInstance()?.app.notifyAppLoaded();
  return createUrlTreeFromSnapshot(route, ['/timer']);
};
export const teamsOnlyGuard: CanActivateFn = async (route, state) => {
  const teams = inject(TeamsService);
  if (teams.getInstance()) {
    teams.getInstance()?.app.notifyAppLoaded();
    return true;
  }
  return createUrlTreeFromSnapshot(route, ['/not-found']);
};
