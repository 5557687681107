import { Component, OnInit, signal } from '@angular/core';
import { pushError } from '@app/_helpers/globalErrorHandler';
import { nextTickAsync } from '@app/_helpers/utils';
import { authorizeMsal } from '@app/app-auth-handler';
import { TeamsService } from '@app/services/teams.service';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom, fromEvent, switchMap } from 'rxjs';
import { Logger } from 'timeghost-api';
const log = new Logger('TeamsLoginPageComponent');
export const teamsScopes = [
  'offline_access',
  'User.Read',
  'Calendars.Read',
  'openid',
  'profile',
  'People.Read',
  'User.ReadBasic.All',
];
@UntilDestroy()
@Component({
  selector: 'tg-teams-login-page',
  templateUrl: './teams-login-page.component.html',
  styleUrl: './teams-login-page.component.scss',
})
export class TeamsLoginPageComponent implements OnInit {
  readonly appInBrowserHref = environment.baseUrl;
  constructor(
    private msal: MsalService,
    private teams: TeamsService,
  ) {}
  async ngOnInit() {
    log.debug('init');
    this.teams.getInstance()?.app.notifyAppLoaded();
    await this.initializeTeamsFrame();
    fromEvent(window, 'message')
      .pipe(untilDestroyed(this), switchMap(this.handleWindowMessages.bind(this)))
      .subscribe();
    log.debug('ready');
  }
  wizardUrl = signal<string>(null);
  isLocal = signal(false);
  requireTeamsFrame = signal(false);
  showRegister = signal(false);
  async initializeTeamsFrame() {
    let wizardUrl = 'https://register-teams.timeghost.io';
    const isLocal = location.hostname === 'localhost';
    if (location.origin == 'https://web-dev.timeghost.io' || isLocal) {
      wizardUrl = 'https://timeghost-wizard-git-dev-timeghost.vercel.app';
    } else if (location.origin == 'https://web-beta.timeghost.io') {
      wizardUrl = 'https://timeghost-wizard-git-beta-timeghost.vercel.app';
    }

    await this.microsoftTeams.getContext().then((context) => {
      const locale = context.app.locale;
      const allowedLocales = ['en', 'de', 'fr', 'es'];
      if (locale && allowedLocales.includes(locale)) {
        wizardUrl + `/${locale}?login_hint=` + context.user.loginHint + '&user_id=' + context.user.id;
      } else {
        wizardUrl + '/?login_hint=' + context.user.loginHint + '&user_id=' + context.user.id;
      }
    });
    this.requireTeamsFrame.set(true);
    await nextTickAsync();
    if (this.teamsFrame && this.teamsFrame.contentDocument.readyState !== 'complete')
      await firstValueFrom(fromEvent(this.teamsFrame, 'load')); // await load
    this.wizardUrl.set(wizardUrl);
    this.isLocal.set(isLocal);
  }
  get microsoftTeams() {
    return this.teams.getInstance()?.app;
  }
  private auth_local() {
    return this.teams.getInstance().authentication.authenticate({
      url: window.location.origin + '/teams-auth.html',
      width: 600,
      height: 535,
    });
  }
  get teamsFrame() {
    return document.getElementById('iframe_teams') as HTMLIFrameElement;
  }
  async handleWindowMessages(e: MessageEvent<any>) {
    log.debug('onmessage', e);
    if ((!this.isLocal() && !(e.origin + '').startsWith(this.wizardUrl())) || !e.data || !e.data.client_id) return;

    localStorage.teams_auth_scopes = e.data.scopes;
    localStorage.teams_auth_clientId = e.data.client_id;
    localStorage.teams_auth_scope_url = e.data.scope_url;
    const wizardUrl = this.wizardUrl();
    await this.auth_local()
      .then(async (x: any) => {
        const instance = new PublicClientApplication({
          auth: {
            clientId: localStorage.teams_auth_clientId,
            authority: `https://login.microsoftonline.com/common`,
            redirectUri: `${window.location.origin}/teams-auth-end.html`,
            navigateToLoginRequestUrl: false,
          },
          cache: {
            cacheLocation: 'localStorage',
          },
        });
        await instance.initialize();
        instance
          .acquireTokenSilent({
            scopes: [localStorage.teams_auth_scope_url],
            account: x.account,
          })
          .then((response) => {
            instance
              .acquireTokenSilent({
                scopes: ['User.Read'],
                account: x.account,
              })
              .then((graphToken) => {
                this.teamsFrame.contentWindow.postMessage(
                  { accessToken: response.accessToken, graphToken: graphToken.accessToken },
                  wizardUrl,
                );
                this.showRegister.set(true);
              })
              .catch((error) => {
                console.error(error);
                location.href = wizardUrl;
              });
          });
      })
      .catch((reason) => {
        console.error(reason);
        this.teamsFrame.contentWindow.postMessage(reason, wizardUrl);
      });
  }
  loading = signal(false);
  login() {
    this.loading.set(true);
    authorizeMsal({ http: null, msal: this.msal, teams: this.teams })
      .finally(() => {
        this.loading.set(false);
        this.teams.getInstance()?.app.notifyAppLoaded();
      })
      .then((res) => {
        log.debug(res);
        if (res) window.location.href = window.location.origin + '/timer';
      })
      .catch((err) => {
        pushError(err);
        log.error(err);
      });
  }
}
